<template>
  <div>
    <v-card>
      <v-card-title> {{ form.id > 0 ? "修改" : "新增" }}规则映射 </v-card-title>
      <v-card-text>
        <v-text-field label="域名" v-model="form.domain"></v-text-field>
        <v-text-field
          label="资产地址"
          v-model="form.ip_addr_n64"
        ></v-text-field>
        <v-text-field
          label="隐身地址"
          v-model="form.ip_addr_n66"
        ></v-text-field>
        <v-text-field label="源地址" v-model="form.src_ip_addr"></v-text-field>
        <v-btn
          :loading="loading"
          depressed
          color="primary"
          block
          @click="submit"
        >
          提交
        </v-btn>
        <v-btn depressed class="mt-1" block @click="cancel"> 取消 </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        domain: "",
        ip_addr_n64: "",
        ip_addr_n66: "",
        src_ip_addr: "",
        id: 0,
      },
    };
  },
  methods: {
    getOne() {
      let id = sessionStorage.getItem("rulesMappingId");
      if (id && id > 0) {
        this.$axios
          .get(`/trunkserver/assets/rules-mapping/${id}/`)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.form = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submit() {
      this.loading = true;
      if (this.form.id && this.form.id > 0) {
        //修改put
        this.$axios
          .put(`/trunkserver/assets/rules-mapping/${this.form.id}/`, this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("rulesMappingId", 0);
              this.$router.push({
                path: "/domain/rules_mapping",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        console.log(this.form);
        this.$axios
          .post("/trunkserver/assets/rules-mapping/", this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("rulesMappingId", 0);
              this.$router.push({
                path: "/domain/rules_mapping",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    cancel() {
      sessionStorage.setItem("rulesMappingId", 0);
      this.$router.push({
        path: "/domain/rules_mapping",
      });
    },
  },
  created() {
    this.getOne();
  },
};
</script>

<style lang="scss" scoped></style>
